<template>
    <div>
		<el-form ref="queryForm" class="query-box"  v-model="queryParams" label-width="68px">
			<el-row :gutter="20">
				<el-col :span="6">
					<el-form-item label="渠道编码" prop="channelNo">
						<el-input v-model="queryParams.channelNo"
								  placeholder="请输入渠道编码" clearable
								  size="small" @keyup.enter.native="handleQuery"/>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="渠道名称" prop="name">
						<el-input v-model="queryParams.name"
								  placeholder="请输入渠道名称" clearable
								  size="small" @keyup.enter.native="handleQuery"/>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="渠道代码" prop="code">
						<el-input v-model="queryParams.code"
								  placeholder="请输入渠道代码" clearable
								  size="small" @keyup.enter.native="handleQuery"/>
					</el-form-item>
				</el-col>
				<el-col :span="6">
                    <el-form-item label="是否可用" prop="available">
						<el-select v-model="available" clearable size="small" style="width: 100%" @change="changeAvailable">
							<el-option v-for="(value,key) in dicts.ACQ_CHANNEL_INFO_AVAILABLE" :key="key" :label="value" :value="value"/>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>

		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">{{translate('OPERATION.QUERY')}}</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="handleResetQuery">{{translate('OPERATION.RESET')}}</el-button>
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd" v-permission="'BASIC_CONFIG_INSERT'">{{translate('OPERATION.ADD')}}</el-button>
			</el-col>
		</el-row>

		<CustomForm :dicts="dicts" :on-fresh="showCustomForm" @on-close="handlerCustomFormOnclose" :isAdd="true" :isLook="true"></CustomForm>
	</div>
</template>

<script>
	import CustomForm from "./CustomForm";
	import {BasicApi} from '@/api';
    export default {
        name: "ToolBar",
		components: {CustomForm},
		props: {
			dicts: {
				type: Object,
				default: {}
			}
		},
		data() {
			return {
				queryParams: {},
				createDateRange: [],
				updateDateRange: [],
				showCustomForm: false,
				available: ''
			}
		},
		mounted() {
		},
		methods: {
			handlerCustomFormOnclose() {
				this.showCustomForm = false;
				this.commitChange();
			},
			handleQuery() {
				this.commitChange();
			},
			handleResetQuery(){
				this.queryParams = {};
				this.available = "";
				this.createDateRange = [];
				this.updateDateRange = [];
				this.commitChange();
			},
			handleAdd(){
				this.showCustomForm = true;
			},
			handleExport() {
				this.$confirm(this.translate('FROM.CONFIRM_EXPORT', {module: 'MODULE.CONFIG'}), this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					this.dealWithQueryParams();
					let result = (await BasicApi.config.export(this.queryParams));
					if (result.success) {
						this.downloadFile(result.data);
					}
				});
			},
			dealWithQueryParams() {
				let [startCreateTime, endCreateTime] = this.createDateRange;
				this.queryParams.startCreateTime = startCreateTime && (startCreateTime + ' 00:00:00');
				this.queryParams.endCreateTime = endCreateTime && (endCreateTime + ' 23:59:59');

				let [startUpdateTime, endUpdateTime] = this.updateDateRange;
				this.queryParams.startUpdateTime = startUpdateTime && (startUpdateTime + ' 00:00:00');
				this.queryParams.endUpdateTime = endUpdateTime && (endUpdateTime + ' 23:59:59');
			},
			commitChange() {
				this.dealWithQueryParams();
				let params = {...this.queryParams};
				this.$emit('on-change', params);
			},
			changeAvailable(val){
				if(val=='可用'){
					this.queryParams.available = 1
				}else if(val=='不可用'){
					this.queryParams.available = 0
				}else{
					this.queryParams.available = ""
				}
			}
		}
    }
</script>

<style scoped>

</style>
